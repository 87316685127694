
import { User } from "@/store/user/types";
import { Program, StateType, } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AdminPresenceProgramCard extends Vue {
	@Prop({ required: true }) program!: Program;

	@Prop({ required: true }) participant!: User
	@Prop({ required: true }) coach!: User

	loading = true;


	get backgroundColor(): string {
		if (this.program.isArchived) return '#FF5252';
		if (this.program.state === StateType.ENDED) return '#FB8C00'

		return '#659b37';
	}
}
